<mat-toolbar class="toolbar" color="primary">
    <mat-toolbar-row>
        <button mat-icon-button routerLink="startseite">
            <img class="toolbar-icon" src="assets/icon/tsv.jpg">
        </button>
        <button mat-button class="toolbar-button" routerLink="/sportarten">
            <mat-icon>sports_handball</mat-icon>
            <span class="hide-sm">Sportarten</span>
        </button>
        <button mat-button class="toolbar-button" routerLink="/veranstaltungen">
            <mat-icon>event</mat-icon>
            <span class="hide-sm">Veranstaltungen</span>
        </button>
        <span class="all-spacer"></span>
        <button mat-button class="toolbar-user-menu-button" [matMenuTriggerFor]="menu">
            <mat-icon>menu</mat-icon>
            <mat-icon>expand_more</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <!-- <button mat-menu-item routerLink="/einstellungen">
                <mat-icon>settings</mat-icon>
                <span>Einstellungen</span>
            </button> -->
            <button mat-menu-item routerLink="sponsoren">
                <mat-icon>volunteer_activism</mat-icon>
                <span>Sponsoren</span>
            </button>
            <button mat-menu-item routerLink="kontakt">
                <mat-icon>store</mat-icon>
                <span>Geschäftsstelle</span>
            </button>
            <button mat-menu-item routerLink="clubhaus">
                <mat-icon>home</mat-icon>
                <span>Clubhaus</span>
            </button>
            <button mat-menu-item routerLink="faq">
                <mat-icon>help</mat-icon>
                <span>FAQ</span>
            </button>
            <button mat-menu-item routerLink="admin" *ngIf="user?.roles?.includes(adminRole)">
                <mat-icon>admin_panel_settings</mat-icon>
                <span>Administration</span>
            </button>
            <mat-divider></mat-divider>
            <button mat-menu-item (click)="signOut()">
                <mat-icon>logout</mat-icon>
                <span>Abmelden</span>
            </button>
        </mat-menu>
    </mat-toolbar-row>
</mat-toolbar>