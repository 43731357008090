import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { Sponsor } from 'src/app/models/sponsor.model';
import { SponsorService } from 'src/app/services/sponsor.service';

@Component({
  selector: 'tsv-sponsor-list',
  templateUrl: './sponsor-list.component.html',
  styleUrls: ['./sponsor-list.component.scss']
})
export class SponsorListComponent implements OnInit {

  sponsors$: Observable<Sponsor[]>;

  constructor(
    public sponsorService: SponsorService,
    private titleService: Title) { }

  ngOnInit(): void {
    this.sponsors$ = this.sponsorService.getAll();
    this.titleService.setTitle('Danke an unsere Sponsoren!');
  }

}
