import { AuthService } from './../../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Roles } from 'src/app/models/roles.enum';
import { User } from 'src/app/models/user.model';
import { NewsCreateComponent } from '../news/news-create/news-create.component';
import { Title } from '@angular/platform-browser';

@Component({
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {

  reporterRole: Roles = Roles.REPORTER;
  adminRole: Roles = Roles.ADMIN;
  user: User;

  constructor(
    private authService: AuthService,
    public dialog: MatDialog,
    private titleService: Title
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('TSV Travemünde');
    this.authService.user$.subscribe(user => this.user = user);
  }

  openDialog(): void {
    this.dialog.open(NewsCreateComponent, { maxWidth: '600px', width: '100%' });
  }
}
