import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Sponsor } from 'src/app/models/sponsor.model';
import { SponsorService } from 'src/app/services/sponsor.service';
import { SponsorCreateUpdateComponent } from './sponsor-create-update/sponsor-create-update.component';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'tsv-admin-sponsor',
  templateUrl: './admin-sponsor.component.html',
  styleUrls: ['./admin-sponsor.component.scss']
})
export class AdminSponsorComponent {

  sponsors$: Observable<Sponsor[]>;

  constructor(
    public sponsorService: SponsorService,
    public dialog: MatDialog,
    private titleService: Title
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('Administration - Sponsoren');
    this.sponsors$ = this.sponsorService.getAll();
  }

  openDialog(sponsor?: Sponsor): void {
    if (sponsor) {
      this.dialog.open(SponsorCreateUpdateComponent, { data: sponsor });
    } else {
      this.dialog.open(SponsorCreateUpdateComponent)
    }
  }

  delete(sponsor: Sponsor): void {
    this.sponsorService.delete(sponsor);
  }

}
