import { Component } from '@angular/core';

@Component({
  selector: 'tsv-sponsor-paypal-button',
  templateUrl: './sponsor-paypal-button.component.html',
  styleUrls: ['./sponsor-paypal-button.component.scss']
})
export class SponsorPaypalButtonComponent {

  hostedButtonId: string = 'Y8NH7KX3LUU6E';

  openPayPal(): void {
    const url = `https://www.paypal.com/donate?hosted_button_id=${this.hostedButtonId}`;
    window.open(
      url,
      '_blank',
      'width=500,height=700,scrollbars=yes,resizable=yes'
    );
  }

}
