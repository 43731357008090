<div class="sponsor-wrapper">
    <h1>Sponsoren</h1>

    <mat-expansion-panel class="donation-information-panel">
        <mat-expansion-panel-header>
            <mat-panel-title>
                Danke an unsere Sponsoren!
            </mat-panel-title>
            <mat-panel-description>
                Du möchtest auch etwas dazu beitragen?
            </mat-panel-description>
        </mat-expansion-panel-header>
        <section>
            <h3>Unsere Bankverbindung</h3>
            <p><strong>TSV Travemünde</strong></p>
            <p>
                <strong>IBAN:</strong> DE58 2305 0101 0004 4034 57<br>
                <strong>BIC:</strong> NOLADE21SPL<br>
                <strong>Bank:</strong> Sparkasse zu Lübeck
            </p>
        </section>
        <section>
            <h3>PayPal-Spenden</h3>
            Du kannst uns ganz einfach über PayPal unterstützen!
            <br><br>
            <tsv-sponsor-paypal-button></tsv-sponsor-paypal-button>
        </section>
    </mat-expansion-panel>

    <div class="list">
        <tsv-sponsor-card *ngFor="let sponsor of sponsors$ |async" [sponsor]="sponsor"
            class="sponsor-item"></tsv-sponsor-card>
    </div>

</div>