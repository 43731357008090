import { Component, Input } from '@angular/core';
import { Sponsor } from 'src/app/models/sponsor.model';

@Component({
  selector: 'tsv-sponsor-card',
  templateUrl: './sponsor-card.component.html',
  styleUrls: ['./sponsor-card.component.scss']
})
export class SponsorCardComponent {

  @Input() sponsor: Sponsor;

  @Input() admin: boolean = false;

}
