import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Sponsor } from 'src/app/models/sponsor.model';
import { SponsorService } from 'src/app/services/sponsor.service';

/**
 * Component to create or update a sponsor.
 */

@Component({
  selector: 'tsv-sponsor-create-update',
  templateUrl: './sponsor-create-update.component.html',
  styleUrls: ['./sponsor-create-update.component.scss']
})
export class SponsorCreateUpdateComponent implements OnInit {

  // Form group for the sponsor data
  form = this.formBuilder.group({
    name: [''],
    url: [''],
    imageUrl: [''],
    donationAmount: [0]
  });
  imageUrl: string | null = null;


  constructor(
    @Inject(MAT_DIALOG_DATA) public sponsor: Sponsor,
    private dialogRef: MatDialogRef<SponsorCreateUpdateComponent>,
    private formBuilder: FormBuilder,
    private sponsorService: SponsorService
  ) { }

  /**
   * Initializes the form with the sponsor data if it exists.
   */
  ngOnInit(): void {
    this.initForm();
  }

  /**
   * Initializes the form with the sponsor data if a sponsor is provided.
   */
  initForm(): void {
    if (this.sponsor?.id) {
      this.form.controls.name.setValue(this.sponsor.name);
      this.form.controls.url.setValue(this.sponsor.url);
      this.form.controls.donationAmount.setValue(this.sponsor.donationAmount!);
      this.imageUrl = this.sponsor.imageUrl ? this.sponsor.imageUrl : null;
    }
  }

  /**
   * Creates a new sponsor.
   */
  create(): void {
    const sponsor: Sponsor = {
      created: new Date().getTime(),
      modified: new Date().getTime(),
      name: this.form.get('name')?.value!,
      url: this.form.get('url')?.value!,
      imageUrl: this.imageUrl ? this.imageUrl : '',
      donationAmount: this.form.get('donationAmount')?.value!
    };
    this.sponsorService.create(sponsor).then(() => this.dialogRef.close());
  }

  /**
   * Updates an existing sponsor.
   */
  update(): void {
    const sponsor: Sponsor = this.sponsor;
    sponsor.name = this.form.get('name')?.value!;
    sponsor.url = this.form.get('url')?.value!;
    sponsor.imageUrl = this.imageUrl ? this.imageUrl : '';
    sponsor.donationAmount = this.form.get('donationAmount')?.value!;
    sponsor.modified = new Date().getTime();
    this.sponsorService.update(sponsor).then(() => this.dialogRef.close());
  }

  /**
   * Deletes a sponsor.
   */
  delete(): void {
    this.sponsorService.delete(this.sponsor).then(() => this.dialogRef.close());
  }

  /**
   * Gets called on change of the emitted downloadURL of the image-picker and syncs the url to the create object.
  * @param url string
  */
  syncDownloadURL(url: string) {
    this.imageUrl = url;
  }
}
