<mat-card class="card">
    <mat-card-content class="content">
        <div class="img-wrapper" *ngIf="sponsor.imageUrl">
            <img [src]="sponsor.imageUrl" [alt]="'The logo of the sponsor: ' + sponsor.name" class="logo">
        </div>
        <div class="icon-wrapper" *ngIf="!sponsor.imageUrl">
            <mat-icon color="primary">volunteer_activism</mat-icon>
        </div>
    </mat-card-content>
    <mat-card-header>
        <div class="details">
            <mat-card-title class="title">{{sponsor.name}}</mat-card-title>
            <a [href]="sponsor.url" target="_blank" class="url-wrapper" *ngIf="!admin">
                <mat-card-subtitle class="url">{{sponsor.url}}</mat-card-subtitle>
            </a>
            <div class="url-wrapper" *ngIf="admin">
                <mat-card-subtitle class="url">{{sponsor.url}}</mat-card-subtitle>
            </div>
        </div>
    </mat-card-header>
</mat-card>