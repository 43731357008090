<mat-drawer-container class="admin" [hasBackdrop]="false">
  <mat-drawer #drawer mode="over">
    <mat-nav-list class="navigation">
      <a mat-list-item (click)="drawer.toggle()">
        <h4 class="nav-header">Admin Navigation</h4>
      </a>
      <mat-divider></mat-divider>
      <a mat-list-item class="navigation-item" [routerLink]="navigation.link" [matTooltip]="navigation.name"
        matTooltipPosition="after" *ngFor="let navigation of navigationItems" routerLinkActive="active"
        (click)="drawer.toggle()">
        <mat-icon matListItemIcon class="icon">{{ navigation.icon }}</mat-icon>
        <span>{{ navigation.name }}</span>
      </a>
    </mat-nav-list>
  </mat-drawer>
  <mat-drawer-content class="outlet-wrapper">
    <mat-nav-list class="wrapped-nav">
      <a mat-list-item (click)="drawer.toggle()">
        <mat-icon matListItemIcon>menu</mat-icon>
      </a>
      <mat-divider></mat-divider>
      <a mat-list-item class="navigation-item" [matTooltip]="navigation.name" matTooltipPosition="after"
        [routerLink]="navigation.link" routerLinkActive="active" *ngFor="let navigation of navigationItems">
        <mat-icon matListItemIcon class="icon">{{ navigation.icon }}</mat-icon>
      </a>
    </mat-nav-list>
    <div class="outlet">
      <router-outlet></router-outlet>
    </div>
  </mat-drawer-content>
</mat-drawer-container>