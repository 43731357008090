import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { Sponsor } from '../models/sponsor.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SponsorService {

  private sponsorsCollection: AngularFirestoreCollection<Sponsor>;

  constructor(
    private firestore: AngularFirestore
  ) {
    this.sponsorsCollection = firestore.collection<Sponsor>('sponsors');
  }

  /**
   * Creates a new sponsor in the Firestore database.
   * @param sponsor The sponsor object to create.
   * @returns A promise that resolves when the sponsor is created.
   */
  create(sponsor: Sponsor): Promise<void> {
    sponsor.id = this.firestore.createId();
    return this.sponsorsCollection.doc(sponsor.id).set(sponsor);
  }

  /**
   * Updates an existing sponsor in the Firestore database.
   * @param sponsor The sponsor object to update.
   * @returns A promise that resolves when the sponsor is updated.
   */
  update(sponsor: Sponsor): Promise<void> {
    return this.sponsorsCollection.doc(sponsor.id).set(sponsor);
  }

  /**
   * Deletes a sponsor from the Firestore database.
   * @param sponsor The sponsor object to delete.
   * @returns A promise that resolves when the sponsor is deleted.
   */
  delete(sponsor: Sponsor): Promise<void> {
    return this.sponsorsCollection.doc(sponsor.id).delete();
  }

  /**
   * Retrieves a sponsor from the Firestore database by ID.
   * @param id The ID of the sponsor to retrieve.
   * @returns An observable that emits the sponsor object.
   */
  getById(id: string): Observable<Sponsor | any> {
    return this.sponsorsCollection.doc(id).valueChanges();
  }

  /**
   * Retrieves all sponsors from the Firestore database,
   * ordered by the donation amount descending.
   * @returns An observable that emits an array of sponsor objects.
   */
  getAll(): Observable<Sponsor[]> {
    return this.firestore.collection<Sponsor>('sponsors', ref => ref
      .orderBy('donationAmount', 'desc')
    ).valueChanges();
  }
}
