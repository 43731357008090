import { Title } from '@angular/platform-browser';
import { Sport } from './../../models/sport.model';
import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { News } from 'src/app/models/news.model';
import { SportService } from 'src/app/services/sport.service';

@Component({
  selector: 'tsv-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {

  //TODO: change this component to the list and create a news card as the others
  //TODO: remove the author from the title and add them as another subtitle

  @Input() news: News;

  sport$: Observable<Sport>;

  constructor(
    private sportService: SportService,
    private titleService: Title
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('Aktuelles und Neuigkeiten');
    this.fetchAuthorIfExistent();
  }

  private fetchAuthorIfExistent(): void {
    if (this.news.authorId !== '') {
      this.sport$ = this.sportService.getSportById(this.news.authorId!);
    }
  }

}
