<div class="sponsors">
    <h3>Sponsoren</h3>

    <div class="search-row">
        <div class="all-spacer"></div>
        <button mat-raised-button class="hide-sm" (click)="openDialog()">
            Hinzufügen
        </button>
        <button mat-icon-button class="hide-lg" (click)="openDialog()">
            <mat-icon>add</mat-icon>
        </button>
    </div>

    <div class="list">
        <tsv-sponsor-card *ngFor="let sponsor of sponsors$ |async" [sponsor]="sponsor" [admin]="true"
            class="sponsor-item" (click)="openDialog(sponsor)"></tsv-sponsor-card>
    </div>
</div>