<h1 mat-dialog-title>Sponsoren</h1>
<div mat-dialog-content>
    <form [formGroup]="form" class="form">

        <div class="image-picker">
            <tsv-image-picker *ngIf="!sponsor" (downloadURLEvent)="syncDownloadURL($event)"
                [ratio]="1"></tsv-image-picker>
            <tsv-image-picker *ngIf="sponsor" (downloadURLEvent)="syncDownloadURL($event)" [ratio]="1"
                [img]="sponsor.imageUrl!"></tsv-image-picker>
        </div>

        <div class="formfield-with-hint">
            <mat-form-field appearance="outline" class="formfield">
                <mat-label>Name</mat-label>
                <input matInput formControlName="name" required>
            </mat-form-field>
            <button mat-icon-button matTooltip="Name des Sponsors." matTooltipPosition="left">
                <mat-icon>help</mat-icon>
            </button>
        </div>

        <div class="formfield-with-hint">
            <mat-form-field appearance="outline" class="formfield">
                <mat-label>Website</mat-label>
                <input matInput formControlName="url" required>
            </mat-form-field>
            <button mat-icon-button matTooltip="Website des Sponsors. Beispiel: tsv-travemuende.com"
                matTooltipPosition="left">
                <mat-icon>help</mat-icon>
            </button>
        </div>

        <div class="formfield-with-hint">
            <mat-form-field appearance="outline" class="formfield">
                <mat-label>Spende</mat-label>
                <input matInput type="number" formControlName="donationAmount" min="0">
            </mat-form-field>
            <button mat-icon-button matTooltip="Optionale Höhe der monatlichen Spende des Sponsors."
                matTooltipPosition="left">
                <mat-icon>help</mat-icon>
            </button>
        </div>

    </form>
</div>
<div mat-dialog-actions class="actions">
    <button mat-icon-button matDialogClose class="hide-lg" aria-label="Close dialog">
        <mat-icon>close</mat-icon>
    </button>
    <button mat-button matDialogClose class="hide-sm"><span>Abbrechen</span></button>

    <button *ngIf="!sponsor" mat-icon-button color="primary" [disabled]="form.invalid" (click)="create()"
        class="hide-lg" aria-label="Create sponsor">
        <mat-icon>check</mat-icon>
    </button>
    <button *ngIf="!sponsor" mat-raised-button color="primary" [disabled]="form.invalid" (click)="create()"
        class="hide-sm">Erstellen</button>

    <button *ngIf="sponsor" mat-icon-button color="warn" (click)="delete()" class="hide-lg" aria-label="Delete sponsor">
        <mat-icon>delete</mat-icon>
    </button>
    <button *ngIf="sponsor" mat-raised-button color="warn" (click)="delete()" class="hide-sm">Löschen</button>

    <button *ngIf="sponsor" mat-icon-button color="primary" [disabled]="form.invalid" (click)="update()" class="hide-lg"
        aria-label="Save sponsor">
        <mat-icon>save</mat-icon>
    </button>
    <button *ngIf="sponsor" mat-raised-button color="primary" [disabled]="form.invalid" (click)="update()"
        class="hide-sm">Speichern</button>
</div>