import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Observable, filter, forkJoin, map, mergeMap, of } from 'rxjs';
import { AuthorType } from 'src/app/models/authorType.enum';
import { Event } from 'src/app/models/event.model';
import { News } from 'src/app/models/news.model';
import { Sport } from 'src/app/models/sport.model';
import { EventService } from 'src/app/services/event.service';
import { NewsService } from 'src/app/services/news.service';
import { SportService } from 'src/app/services/sport.service';

@Component({
  selector: 'tsv-external',
  templateUrl: './external.component.html',
  styleUrls: ['./external.component.scss']
})
export class ExternalComponent implements OnInit {

  sportsClubUrl: string = 'https://firebasestorage.googleapis.com/v0/b/dev-tsv-travemuende.appspot.com/o/default%2Fdefault-tsv-sports-club.jpg?alt=media&token=ae470521-0eb7-46c7-9558-d12fddf74ae6';
  sports$: Observable<Sport[]>;
  news$: Observable<News[]>;
  events$: Observable<Event[]>;
  mergedNews: News[] = [];

  constructor(
    private newsService: NewsService,
    private sportService: SportService,
    private eventService: EventService,
    private titleService: Title
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('TSV Travemünde');
    this.news$ = this.newsService.getFeaturedNews();
    this.getNews();
    this.sports$ = this.sportService.getSports(9);
    this.events$ = this.eventService.getAllUpcoming().pipe(map(events => events.filter(event => event.authorType != AuthorType.TEAM)));
  }

  getNews(): void {
    this.newsService.getFeaturedUntilNews().subscribe(featuredUntilNews => {
      this.mergedNews = [];
      this.mergedNews = this.mergedNews.concat(featuredUntilNews);

      this.newsService.getFeaturedNews().subscribe(featuredNews => {
        featuredNews.forEach(news => {
          if (this.mergedNews.length <= 4) {
            this.mergedNews = this.mergedNews.concat(news);
          }
        })
      })
    });
  }

}
